const UPDATE_CART_NUMBER = 'UPDATE_CART_NUMBER';

export default {
  namespaced: true,
  state: {
    cartNumber: 0
  },
  actions: {
    updateCartNumber({ commit }, payload) {
      commit(UPDATE_CART_NUMBER, payload);
    },
  },
  mutations: {
    UPDATE_CART_NUMBER(state, payload) {
      state.cartNumber = payload;
    },
  },
}